<template>
	<main-table-a>
		<div slot="table" class="df f1 fdc">
			<div class="df fww">
				<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
					<div class="df jcsb mb5">
						<div class="fs16 fw6 c3">{{counts["status_"+s] || 0}}</div>
					</div>
					<div class="fs14 c6">{{status.name}}</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
					<el-button type="primary" size="mini" @click="create_dialog = true" :disabled="!$utils.create($api.URI_ASSETS)">添加</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="资产名称/编号/序号" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="选择类型" filterable  multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(type, t) in types" :key="t" :label="type" :value="t">
							<span>{{ type }}</span>
							<span class="c9 fs12">{{counts["type_"+t] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getAssets({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="position_table" class="bill scroll-wrapper pa" height="100%" :data="assets" :size="theme.size">
					<el-table-column label="编号" prop="no" min-width="140">
						<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_ASSETS)" @click="asset = {...scope.row}; create_dialog = true;">{{scope.row.no}}</el-link>
					</el-table-column>
					<el-table-column label="名称" prop="name" min-width="150"></el-table-column>
					<el-table-column label="类型" prop="type" min-width="80" :formatter="({type}) => { return types[type] || '未知'; }"></el-table-column>
					<el-table-column label="型号" prop="model" min-width="50"></el-table-column>
					<el-table-column label="序号" prop="serial" min-width="100"></el-table-column>
					<el-table-column label="位置" prop="location" min-width="70"></el-table-column>
					<el-table-column label="状态" prop="status" min-width="70" :formatter="({status}) => { return statuses[status] || '未知'; }"></el-table-column>
					<el-table-column label="备注说明" prop="remarks" min-width="150"></el-table-column>
					<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
					<el-table-column label="操作" width="60">
						<template slot-scope="scope">
							<el-popconfirm title="确定恢复这个资产吗？" @confirm="handleAsset({action: 'restore', id: scope.row.id})" v-if="scope.row.deleted_at">
								<el-button slot="reference" type="text" :disabled="!$utils.restore($api.URI_ASSETS)">恢复</el-button>
							</el-popconfirm>
							<el-popconfirm title="确定删除这个资产吗？" @confirm="onDelete(scope.row)" v-else>
								<el-button slot="reference" type="text" :disabled="!$utils.delete($api.URI_ASSETS)">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getAssets({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getAssets({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<el-dialog :title="asset.id ? '更新资产' : '创建资产'" width="360px" :visible.sync="create_dialog" @closed="asset = {}" append-to-body destroy-on-close>
				<el-form label-width="70px" ref="asset_form" :model="asset" :rules="rules" :size="theme.size" status-icon>
					<el-form-item label="名称" prop="name">
						<el-input v-model="asset.name" placeholder="请输入资产名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="类型" prop="type">
						<el-select v-model="asset.type" placeholder="请选择类型" filterable>
							<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="型号" prop="model">
						<el-input v-model="asset.model" placeholder="请输入型号" clearable></el-input>
					</el-form-item>
					<el-form-item label="序号" prop="serial">
						<el-input v-model="asset.serial" placeholder="请输入序列号" clearable></el-input>
					</el-form-item>
					<el-form-item label="位置" prop="location">
						<el-input v-model="asset.location" placeholder="请输入存在位置" clearable></el-input>
					</el-form-item>
					<el-form-item label="备注说明" prop="remarks">
						<el-input v-model="asset.remarks" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('asset_form')">{{asset.id ? '提交更新' : '提交创建'}}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			asset_dict () {
				return this.models.assets || {};
			},
			statuses () {
				return this.asset_dict.statuses || {};
			},
			types () {
				return this.asset_dict.types || {};
			},
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		methods: {
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getAssets(this.params);
			},
			async getAssets (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ASSETS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.assets = result.data;
				this.counts = result.counts;
				var status_tabs = {};
				const status = params.status || [];
				for (var s in this.statuses) {
					status_tabs[s] = {
						name: this.statuses[s],
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleAsset (params) {
				const res = await this.$http.get(this.$api.URI_ASSETS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getAssets(this.params);
				this.$message.success(msg);
			},
			onDelete (r) {
				if (r.employees_count) return this.$message.error(`还有 ${r.employees_count} 人在使用`);
				this.handleAsset({action: 'delete', id: r.id});
			},
			async submitAsset (data) {
				const res = await this.$http.post(this.$api.URI_ASSETS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.asset = {};
						this.create_dialog = false;
						this.getAssets({ ...this.params, page: 1});
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'asset_form':
							this.submitAsset(this.asset);
						break;
					}
				});
			}
		},
		data() {
			return {
				asset: {},
				assets: [],
				status_tabs: [],
				show_fillter: false,
				create_dialog: false,
				counts: {},
				params: {
					status: [],
					perPage: 10,
				},
				rules: {
					name: [{ required: true, message: '请输入资产名称', trigger: 'blur' }],
					type: [{ required: true, message: '请选择类型', trigger: 'blur' }]
				},
			}
		},
		created () {
			this.getAssets(this.params, true);
		}
	};
</script>